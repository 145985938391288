import React from 'react';
import Navbar from '../Components/common/Navbar';
import Footer from '../Components/common/Footer';
import Services from '../Components/Services/Service';


   function ServicePage() {
     return (
       <>
         <Navbar />
         <Services/>
         <Footer />
       </>
     );
   }

export default ServicePage;