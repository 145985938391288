import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import levantaLogo from "../../images/logo-red2.png";

// Logo Component
function Logo() {
  return (
    <div className="nav-logo-container">
      <Link to="/">
        <img
          loading="lazy"
          src={levantaLogo}
          className="logo"
          alt="Logo"
        />
      </Link>
    </div>
  );
}

// Navigation Component
function Navigation({ toggleNav, showNav }) {

  const navigate = useNavigate()

  const scrollToContactSection = () => {
    console.log("<<<<<< Contact-Test >>>>>")

    navigate('/#contact', { replace: true });

    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToOurServicesSection = () => {
    console.log("##### Services-Test #####")

    navigate('/#services', { replace: true });

  const serviceSection = document.getElementById("services");
  setTimeout(() => {
    if (serviceSection) {
      serviceSection.scrollIntoView({ behavior: "smooth" });
    }  }, 100);
    
};

  const navLinks = [
    { label: "Home", to: "/#home" },
    { label: "About", to: "/about" },
    { label: "Our Services", onClick: scrollToOurServicesSection },
    { label: "Contact", onClick: scrollToContactSection },
    { label: "B2B Login", to: "https://connect.levantatravel.com", isB2B: true },
  ];

  const location = useLocation();

  return (
    <div className="nav-menu-container">
      <div className="nav-toggle" onClick={toggleNav}>
        <div className={`hamburger ${showNav ? 'is-active' : ''}`}>
           <div className="bar"></div>
        </div>
      </div>
      <nav className={`nav-links ${showNav ? "show" : ""}`}>
        {navLinks.map((link, index) => (
          <React.Fragment key={index}>
            {link.onClick ? (
              <a
                className={`nav-link ${link.isB2B ? "b2b-link" : ""}`}
                onClick={link.onClick}
              >
                {link.label}
              </a>
            ) : (
            <Link
              to={link.to}
              className={`nav-link ${link.isB2B ? "b2b-link" : ""} ${
                location.pathname.startsWith(link.to) ? "selected" : ""
              }`}
              onClick={toggleNav}
            >
              {link.label}
            </Link>


            )}
            

          </React.Fragment>
        ))}
      </nav>
    </div>
  );
}

// Navigation Bar Component
function NavigationBar() {
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <div className="nav-main-wrapper">
      <div className="nav-content">
        <Logo />
        <Navigation toggleNav={toggleNav} showNav={showNav} />
      </div>

      <style jsx>{`
        body {
          margin: 0;
        }

        .nav-main-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #fff;
          padding: 0 10px;
          position: relative;
          position: fixed;
          width: 100%;
          z-index: 1000;
        }

        .nav-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 90px;
          width: 100%;
        }

        @media (max-width: 991px) {
          .nav-content {
            padding: 25px 42px 15px;
            height: 22px;
          }
        }

        .nav-menu-container {
          display: flex;
          gap: 15px;
          align-items: center;
        }

        .hamburger {
          position: relative;
          display: block;
          width: 35px;
          cursor: pointer;
          appearance: none;
          background: none;
          outline: none;
          border: none;
        }

        .hamburger .bar,
        .hamburger:after,
        .hamburger:before {
          content: '';
          display: block;
          width: 80%;
          height: 2px;
          background-color: #333438;
          margin: 9px 0px;
          transition: 0.4s;
          border-radius: 33px; 
        }

        .hamburger.is-active:before {
          transform: rotate(-45deg) translate(-8px, 6px);
        }

        .hamburger.is-active:after {
          transform: rotate(45deg) translate(-9px, -8px);
        }

        .hamburger.is-active .bar {
          opacity: 0;
        }

        .nav-links {
          display: flex;
          gap: 25px;
          align-items: center;
          justify-content: space-between;
        }

        
        @media (max-width: 991px) {
          .nav-main-wrapper {
            flex-wrap: wrap;
            padding: 0 15px; 
          }

          .nav-logo-container {
            order: 0;
            position: relative;
          }

          .nav-links.show {
            transform: translateY(0);
          }

          .nav-link {
            padding: 10px 0;
          }

          .nav-toggle {
            display: block;
            cursor: pointer;
            position: fixed;
            top: 10px;
            right: 45px;
            padding: 0px;

          }
        }

        .logo {
          aspect-ratio: 1.47;
          object-fit: flex;
          object-position: flex;
          width: 190px;
          max-width: 100%;
          max-height: 36px;
           margin-left: -23px;
        }

        .nav-links {
          display: flex;
          gap: 25px;
          align-items: center; 
          justify-content: space-between;
        }

        .nav-link {
          color: #777777;
          letter-spacing: -0.42px;
          text-transform: uppercase;
          white-space: nowrap;
          justify-content: center;
          align-self: center;
          padding: 10px 0;
          font: 600 14px/107% Manrope, -apple-system, Roboto, Helvetica, sans-serif;
          text-decoration: none;
        }


        .nav-link:hover {
          border-bottom: 2px solid #af1222;
        }
        

        .nav-link:active,
        .nav-link.selected {
          color: #000;  
          border-bottom: 2px solid #af1222;
        }


        .b2b-link {
          color: #fff;
          letter-spacing: -0.12px;
          text-transform: uppercase;
          justify-content: center;
          white-space: nowrap;
          background-color: #af1222;
          border-radius: 36px;
          align-self: center;
          flex-grow: 9;
          margin: auto 0;
          padding: 8px 24px;
          font: 600 14px/147% Manrope, -apple-system, Roboto, Helvetica, sans-serif;
        }

        .nav-toggle {
          display: none;
        }

        .nav-toggle.close {
          display: none;
        }

        @media (max-width: 991px) {
          .nav-toggle {
            display: block;
            cursor: pointer;
          }

          .nav-toggle.close {
            display: block;
            cursor: pointer;
          }

          .nav-links {
            display: none;
            width: 100%;
            flex-direction: column;
            position: absolute;
            top: 52%; 
            right: 1px;
            background-color: #fcfcfc;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            transition: transform 0.3s ease-in-out;
            transform: translateY(0);
          }

          .nav-links.show {
            display: flex;
            transform: translateY(10%);
          }
        }
      `}</style>
    </div>
  );
}

export default NavigationBar;