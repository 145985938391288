// Homepage.jsx
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Components/common/Navbar';
import Footer from '../Components/common/Footer';
import LandingComponent from '../Components/home/Landing';
import TravelCard from '../Components/home/TravelCard';
import OurServices from '../Components/home/Hover';
import Why from '../Components/home/WhyLevanta';
import Form from '../Components/home/ContactForm';
import TravelComponent from '../Components/home/Note';

// Function to scroll to a section based on the hash
const scrollToHashedSection = () => {
  const hash = window.location.hash;
  if (hash) {
    const targetElement = document.querySelector(hash);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
};

// Navigate to the id'ed section in the first hand
function Homepage() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to section based on hash when the location changes
    scrollToHashedSection();
  }, [location]);

  return (
    <>
      <Navbar />
      <div id='home'>
        <LandingComponent />
      </div>
      <TravelCard />
      <div id="services">
        <OurServices />
      </div>
      <TravelComponent />
      <Why />
      <div id="contact">
        <Form />
      </div>
      <Footer />
    </>
  );
}

export default Homepage;
