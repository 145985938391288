import React from 'react';
import Navbar from '../Components/common/Navbar';
import Footer from '../Components/common/Footer';
import About from '../Components/About/About';


   function AboutPage() {
     return (
       <>
         <Navbar />
         <About/>
         <Footer />
       </>
     );
   }

export default AboutPage;